import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchStores = (token, searchCriteria, environment) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/stores',
      data: {
        searchCriteria,
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
