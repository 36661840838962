import { authReducer } from '../modules/auth/auth.redux'
import { environmentsReducer } from '../modules/environments/environments.redux'
import { catalogsReducer } from '../modules/catalogs/catalogs.redux'
import { apigoConfigReducer } from '../modules/apigoConfig/apigoConfig.redux'

export const reducers = {
  auth: authReducer,
  environments: environmentsReducer,
  catalogs: catalogsReducer,
  apigoConfig: apigoConfigReducer,
}
