import authEpics from '../modules/auth/auth.epics'
import environmentsEpics from '../modules/environments/environments.epics'
import catalogsEpics from '../modules/catalogs/catalogs.epics'
import apiGoConfigEpics from '../modules/apigoConfig/apigoConfig.epics'

export const epics = {
  authEpics,
  environmentsEpics,
  catalogsEpics,
  apiGoConfigEpics,
}
