import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchSearchLocations = (token, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: '/api/search/locations',
      data: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchSearchResults = (token, searchCriteria, environment) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/search/text',
      data: {
        searchTerm: searchCriteria.searchTerm,
        searchLocation: searchCriteria.searchLocation,
        fileNamePrefix: searchCriteria.fileNamePrefix,
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
