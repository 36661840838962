import React, { useEffect } from 'react'
import ReactJson from 'react-json-view'
import { makeStyles, Paper, Typography, Toolbar, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getApiGoConfig, resetApiGoConfig, onEdit, onSave } from './apigoConfig.redux'
import { LoadingScreen } from '../../loading/loading'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  buttonRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

export const ApiGoConfig = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)
  const { configMap, disabledButton, isLoading } = useSelector(state => state.apigoConfig)

  useEffect(() => {
    dispatch(resetApiGoConfig())
  }, [dispatch, selectedEnvironment])

  useEffect(() => {
    if (token && configMap.length === 0) {
      dispatch(getApiGoConfig())
    }
  }, [dispatch, token, configMap])

  if (configMap.length === 0 || isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} component="h2" variant="h6" color="primary" gutterBottom>
            <strong>ApiGo Config</strong>
          </Typography>
          <div className={classes.buttonRoot}>
            <Button disabled={disabledButton} onClick={() => dispatch(resetApiGoConfig())}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={disabledButton} onClick={() => dispatch(onSave())}>
              Save
            </Button>
          </div>
        </Toolbar>
        <ReactJson
          src={configMap}
          onEdit={data => dispatch(onEdit(data.updated_src))}
          onAdd={data => dispatch(onEdit(data.updated_src))}
          onDelete={data => dispatch(onEdit(data.updated_src))}
        />
      </Paper>
    </div>
  )
}
