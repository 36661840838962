import React, { useState } from 'react'
import {
  TableContainer,
  useTheme,
  IconButton,
  makeStyles,
  fade,
  Table as MUITable,
  InputBase,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Toolbar,
  Typography,
  TableFooter,
  TablePagination,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  root: {
    marginBottom: 15,
  },
}))

/**
 * This reusable Table component allows you to display a Material UI table by passing an array of objects.
 *
 * @param {object} props
 * @param {Array<object>} props.items the data to be displayed as a table
 * @param {Array<{ name: string, data: (item: object) => string | number | JSX }>} props.columns this props
 * allows you to define the columns you want to use to display the items. The data callback function allows
 * you to resolve the data of each item into information to display on a column
 * @param {string} props.keyColumn the name of the column you want to pass as the key for the .map function
 * @param {boolean} props.noPagination if true, remove the pagination footer from the table and
 * display all rows in the initial load
 * @param {number} props.initialRowsPerPage determine how many rows of record to display per pagination
 * @param {Array<string>} props.searchFilterBy the names of the columns you want to use for the search
 * filter. For example, passing ['name'] will allow the search bar to filter the table by the 'name' column.
 * If you don't provide this array, the search bar will be hidden
 * @param {string} props.title the title name of the table, displayed on top of the table
 */
export const Table = ({
  items,
  columns,
  keyColumn,
  noPagination,
  initialRowsPerPage,
  searchFilterBy,
  handleRowClick,
  title,
}) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = React.useState(noPagination ? 0 : initialRowsPerPage || 5)
  const [page, setPage] = React.useState(0)

  const filteredItems = searchFilterBy
    ? items.filter(item => searchFilterBy.some(column => item[column].toString().match(search)))
    : items

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (items.length === 0) {
    return null
  }

  return (
    <TableContainer component={Paper} className={classes.root}>
      {(title || searchFilterBy) && (
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
          {searchFilterBy && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                value={search}
                onChange={event => setSearch(event.target.value)}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          )}
        </Toolbar>
      )}
      <MUITable aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell width={column.width} key={column.name} align="center">
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map(item => (
            <TableRow hover key={item[keyColumn]} onClick={() => handleRowClick && handleRowClick(item)}>
              {columns.map(column => (
                <TableCell key={column.name + item[keyColumn]} align="center">
                  {column.data(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      {!noPagination && (
        <MUITable>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={filteredItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(_, newPage) => setPage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MUITable>
      )}
    </TableContainer>
  )
}

const useTablePaginationStyle = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useTablePaginationStyle()
  const theme = useTheme()

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}
