import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const getToken = () =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/auth/token',
      baseURL,
      headers: {
        contentType: 'application/x-www-form-urlencoded',
      },
      data: {
        secret: process.env.REACT_APP_AUTH_SECRET_PHRASE.trim(),
      },
    })
  )
