import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../auth/auth.redux'
import { Avatar, Button, Card, CardHeader, CardActions, CardContent } from '@material-ui/core'
import styled from 'styled-components'
import { Redirect } from 'react-router'
import Environments from '../environments/environments'

export const Home = () => {
  const { authenticated, userInfo } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  return (
    <CenteredPage>
      {authenticated && userInfo ? (
        <Card>
          <CardHeader
            avatar={<Avatar src={userInfo.imageUrl} />}
            title={`Welcome, ${userInfo.name}`}
            subheader={userInfo.email}
          />
          <CardContent>
            <Environments />
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={() => dispatch(logout())}>
              Logout
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Redirect to="/login" />
      )}
    </CenteredPage>
  )
}

const CenteredPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
