import React, { Fragment } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Divider, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListAlt,
  Style,
  SettingsApplications,
  ExitToApp,
  VpnKey,
  Home,
  ColorLens,
  FolderOpen,
  Storefront,
  FeaturedPlayList,
  Adjust,
  Search,
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../modules/auth/auth.redux'

const useStyles = makeStyles(theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    padding: 15,
  },
  listItemText: {
    fontSize: '0.85rem',
  },
}))

export const DrawerContent = () => {
  const classes = useStyles()
  const { authenticated, userInfo } = useSelector(state => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  /**
   * You can add or modify the buttons on the sidebar with this array of sections.
   * A "section" is a group of sidebar buttons that is divided from other sections with
   * two thin lines at the top and bottom. This drawer section will only become visible
   * when the user is properly authenticated.
   *
   * A section can have a subheader. This subheader can be undefined if you don't wish
   * to add one for a particular section.
   *
   * To add an icon to a sidebar button, you can import icon components from
   * @material-ui/icons and add it to the "icon" property of a section item. You can go to
   * https://material-ui.com/components/material-icons/ for a catalog of Material Icons and
   * their component name.
   *
   * You can add an onClick or a href for each section item. The onClick will happen before the
   * href if you need to use both of them. If the onClick is a Promise, the href will be delayed
   * until the onClick is done.
   */
  const authenticatedDrawerSections = [
    {
      subheader: `Welcome, ${userInfo && userInfo.name}`,
      items: [
        {
          label: 'Home',
          icon: Home,
          href: '/',
        },
        {
          label: 'Logout',
          icon: ExitToApp,
          onClick: () => dispatch(logout()),
        },
      ],
    },
    {
      subheader: 'Product Audit',
      items: [
        {
          label: 'Products',
          icon: ListAlt,
          href: '/products',
        },
        {
          label: 'Variants',
          icon: Style,
          href: '/variants',
        },
        {
          label: 'Catalog Updates',
          icon: FeaturedPlayList,
          href: '/catalogs',
        },
      ],
    },
    {
      subheader: 'Other',
      items: [
        {
          label: 'Color Groups',
          icon: ColorLens,
          href: '/colorGroups',
        },
        {
          label: 'Stores',
          icon: Storefront,
          href: '/stores',
        },
        {
          label: 'ApiGo Config',
          icon: Adjust,
          href: '/apigoConfig',
        },
      ],
    },
    {
      subheader: 'ETL Files',
      items: [
        {
          label: 'RMS Bucket',
          icon: FolderOpen,
          href: '/rmsBucket',
        },
        {
          label: 'PIM To ETL Bucket',
          icon: FolderOpen,
          href: '/pimToEtlBucket',
        },
        {
          label: 'Digital SFTP',
          icon: FolderOpen,
          href: '/digitalSftp',
        },
        {
          label: 'Search',
          icon: Search,
          href: '/search',
        },
      ],
    },
    {
      subheader: 'ETL Status',
      items: [
        {
          label: 'Cron Job Status',
          icon: SettingsApplications,
          href: '/crons',
        },
      ],
    },
  ]

  const unauthenticatedDrawerSections = [
    {
      subheader: 'Authentication',
      items: [
        {
          label: 'Login',
          icon: VpnKey,
          href: 'login',
        },
      ],
    },
  ]

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {(authenticated ? authenticatedDrawerSections : unauthenticatedDrawerSections).map(
        ({ items, subheader }, index) => (
          <Fragment key={subheader || index}>
            <List
              subheader={
                subheader ? (
                  <ListSubheader component="div" id="nested-list-subheader">
                    {subheader}
                  </ListSubheader>
                ) : undefined
              }
            >
              {items &&
                items.map(({ label, icon: Icon, href, onClick }) => (
                  <ListItem
                    button
                    key={label}
                    onClick={async () => {
                      onClick && (await onClick())
                      href && history.push(href)
                    }}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.listItemText }} primary={label} />
                  </ListItem>
                ))}
            </List>
            <Divider />
          </Fragment>
        )
      )}
    </div>
  )
}
