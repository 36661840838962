import { tryLogin, setDomainSnackbar, loginSuccess, refreshToken, setToken, setLoading } from './auth.redux'
import { getToken } from './auth.io'
import { TTL } from '../../const'
import { of } from 'fluture'

const tryLoginEpic = {
  type: tryLogin.toString(),
  do: ({ payload: response }) => {
    if (['dynamite.ca', 'pivotree.com'].every(domain => !response.profileObj.email.includes(domain))) {
      return of([setDomainSnackbar(true), setLoading(false)])
    }

    return getToken().map(res =>
      loginSuccess({
        userInfo: {
          ...response.profileObj,
          loggedInDate: new Date().toISOString(),
          expiryDate: new Date(Date.now() + TTL).toISOString(),
        },
        token: res.data.token,
      })
    )
  },
}

const refreshTokenEpic = {
  type: refreshToken.toString(),
  do: (_, { auth }) => {
    if (!auth.authenticated) {
      return of()
    }

    return getToken().map(res => setToken(res.data.token))
  },
}

export default [tryLoginEpic, refreshTokenEpic]
