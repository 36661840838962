import React from 'react'
import { useSelector } from 'react-redux'
import { fetchColorGroups } from './colorGroups.io'
import { Table } from '../reusables/Table'
import { CardContent, makeStyles, Toolbar, Typography, Card } from '@material-ui/core'
import { useSearchForm } from '../reusables/useSearchForm'
import { formatDate } from '../../utils/string'

const useStyles = makeStyles(theme => ({
  form: {
    padding: 15,
  },
  card: {
    marginBottom: 15,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const ColorGroups = () => {
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)

  const { form, setLoading, setResults, results } = useSearchForm({
    onSubmit: value => {
      setLoading(true)
      fetchColorGroups(token, value, selectedEnvironment).fork(
        () => {},
        ({ data }) => {
          setResults(data)
          setLoading(false)
        }
      )
    },
    fields: [
      { name: 'colorCode', label: 'Color Code', initialValue: '', type: 'input' },
      { name: 'colorGroup', label: 'Color Group', initialValue: '', type: 'input' },
      { name: 'color', label: 'Color', initialValue: '', type: 'input' },
    ],
  })

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            Color Groups
          </Typography>
        </Toolbar>
        <CardContent>
          <Typography component="div">You can search for color groups using the criteria below:</Typography>
          {form}
        </CardContent>
      </Card>
      <Table
        title="Results"
        keyColumn="colorCode"
        items={results}
        columns={[
          { name: 'Color Code', data: item => item['colorCode'] },
          {
            name: 'Color Group',
            data: item => item['colorGroup'],
          },
          { name: 'Color', data: item => item['color'] },
          {
            name: 'Last Update',
            data: item => formatDate(item['lastUpdated']),
          },
        ]}
      />
    </>
  )
}
