const isDev = process.env.NODE_ENV === 'development'

/**
 * This number dictates how long can a person stay authenticated, in miliseconds. When
 * the time limit expires, the next time they get on the site, they will be logged out.
 *
 * As of now, the auth limit is 3 days.
 */
export const TTL = 3 * 24 * 60 * 60 * 1000

/**
 * Only emails carrying domains from this array will be able to log into the Audit tool.
 */
export const ALLOWED_DOMAINS = ['dynamite.ca', 'pivotree.com']

export const baseURL = isDev ? '' : process.env.REACT_APP_BACKEND_URL

export const USER_GUIDE_URL = 'https://docs.google.com/document/d/12NgYy3YzAAADW6JnSdoi3d_hPc_rxw1rS3MQuKlhNrY'
