import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchVariants = (token, searchCriteria, environment) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/variants',
      data: {
        searchCriteria,
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchVariantById = (token, id, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/api/variants/${id}`,
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchSFCCVariantById = (token, id, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/api/variants/${id}/sfcc`,
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchOmniVariantById = (token, id, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/api/variants/${id}/omni`,
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchVariantsByIds = (token, ids, environment) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/variants/byIds',
      data: {
        ids,
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchInventoryByVariantId = (token, id, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/api/variants/${id}/audits/inventories`,
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
