import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchSearchResults, fetchSearchLocations } from './search.io'
import { CardContent, makeStyles, Toolbar, Typography, Card } from '@material-ui/core'
import { useSearchForm } from '../reusables/useSearchForm'

const useStyles = makeStyles(theme => ({
  form: {
    padding: 15,
  },
  card: {
    marginBottom: 15,
  },
  text: {
    paddingLeft: 8,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const Search = () => {
  const classes = useStyles()
  const [searchLocations, setSearchLocations] = useState([])
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)

  useEffect(() => {
    fetchSearchLocations(token, selectedEnvironment).fork(
      () => {},
      ({ data }) => {
        setSearchLocations(data)
      }
    )
  }, [selectedEnvironment, token])

  const { form, setLoading, setResults, results } = useSearchForm({
    onSubmit: value => {
      setLoading(true)
      fetchSearchResults(token, value, selectedEnvironment).fork(
        err => {
          setResults({
            found: false,
            msg: `Sorry, the search is taking too long. Please try again with a filename pattern or a different word.`,
          })
          setLoading(false)
        },
        ({ data }) => {
          setResults(data)
          setLoading(false)
        }
      )
    },
    fields: [
      { name: 'searchTerm', label: 'Search Term', initialValue: '', type: 'input' },
      { name: 'fileNamePrefix', label: 'Filename Pattern', initialValue: '', type: 'input' },
      {
        name: 'searchLocation',
        label: 'Search Location',
        initialValue: '',
        options: searchLocations,
        type: 'dropdown',
      },
    ],
  })

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            Free Text Search
          </Typography>
        </Toolbar>
        <CardContent>
          <Typography component="div">
            You can search for ETL data in Google buckets and SFTP servers using the criteria below:
          </Typography>
          {form}
        </CardContent>
      </Card>

      {results.found !== undefined && results.found ? (
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" component="div">
              Results
            </Typography>
          </Toolbar>
          <CardContent>
            <Typography className={classes.text} component="div">
              File: {results.file}
            </Typography>
          </CardContent>
        </Card>
      ) : results.found !== undefined && !results.found ? (
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" component="div">
              Results
            </Typography>
          </Toolbar>
          <CardContent>
            <Typography className={classes.text} component="div">
              File: {results.msg}
            </Typography>
          </CardContent>
        </Card>
      ) : null}
    </>
  )
}
