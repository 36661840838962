import { createAction, handleActions } from 'redux-actions'

export const getApiGoConfig = createAction('APIGOCONFIG_GET_APIGOCONFIG')
export const setApiGoConfig = createAction('APIGOCONFIG_SET_APIGOCONFIG')
export const resetApiGoConfig = createAction('APIGOCONFIG_RESET_APIGOCONFIG')
export const onEdit = createAction('APIGOCONFIG_ON_EDIT')
export const save = createAction('APIGOCONFIG_SAVE')
export const onSave = createAction('APIGOCONFIG_ON_SAVE')
export const setLoading = createAction('APIGOCONFIG_SET_LOADING')

const INITIAL_STATE = {
  configMap: '',
  disabledButton: true,
  isLoading: false,
}

export const apigoConfigReducer = handleActions(
  {
    [setApiGoConfig]: (state, { payload }) => ({
      ...state,
      configMap: payload,
      disabledButton: true,
      isLoading: false,
    }),
    [resetApiGoConfig]: state => ({
      ...state,
      configMap: '',
    }),
    [onEdit]: (state, { payload }) => ({
      ...state,
      configMap: payload,
      disabledButton: false,
    }),
    [setLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
  },
  INITIAL_STATE
)
