import { createAction, handleActions } from 'redux-actions'

export const getCatalogs = createAction('CATALOGS_GET_CATALOGS')
export const setCatalogs = createAction('CATALOGS_SET_CATALOGS')

const INITIAL_STATE = {
  items: [],
}

export const catalogsReducer = handleActions(
  {
    [setCatalogs]: (state, { payload: items }) => ({
      ...state,
      items,
    }),
  },
  INITIAL_STATE
)
