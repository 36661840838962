/**
 * Creates a new object array of bucket file meta data based on folder name
 * @param {object} fileMetadata file metadata
 * @param {string} folder folder name
 */
export const filterBucketFilesByFolder = (fileMetadata, folder) =>
  fileMetadata.filter(item => item.name.startsWith(folder))

/**
 * Creates a new object array of bucket file meta data from files not found in any folder
 * @param {object} fileMetadata file metadata
 */
export const filterUnprocessedBucketFiles = fileMetadata =>
  fileMetadata.filter(item =>
    !item.name.startsWith('archived') && !item.name.startsWith('failed') && !item.name.startsWith('adhoc')
      ? item.name
      : null
  )

/**
 * Creates a new object array of digital SFTP file meta data based on folder name
 * @param {object} fileMetadata file metadata
 * @param {string} folder folder name
 */
export const filterDigitalSftpFilesByFolder = (fileMetadata, folder) =>
  fileMetadata.filter(item => item.path.includes(folder))

/**
 * Creates a new object array of digital SFTP file meta data from unprocessed files
 * @param {object} fileMetadata file metadata
 */
export const filterUnprocessedDigitalSftpFiles = fileMetadata =>
  fileMetadata.filter(item =>
    !item.path.includes('archive') && !item.path.includes('failed') && !item.path.includes('product_images')
      ? item.path
      : null
  )
