import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles, Tooltip, Paper, IconButton, Grid, Snackbar } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import { Table } from '../reusables/Table'
import { formatDate } from '../../utils/string'

const LIMIT_IDS = 5

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
})

const FontTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip)

export const CatalogsTable = ({ data, title }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(undefined)
  const [snackPack, setSnackPack] = useState([])

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack(prev => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const handleClick = (itemIds, message) => () => {
    const dummy = document.createElement('input')

    document.body.appendChild(dummy)
    dummy.setAttribute('value', itemIds)
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)

    setSnackPack(prev => [...prev, { message, key: new Date().getTime() }])
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleExited = () => {
    setMessageInfo(undefined)
  }

  const ColumnIds = ({ itemIds, snackPackmsg }) => (
    <Grid container justify="center" spacing={0}>
      <Grid item xs={11}>
        <FontTooltip title={itemIds.slice(LIMIT_IDS).join(', ')}>
          <Paper className={classes.paper}>
            {itemIds.slice(0, LIMIT_IDS).join(', ')}
            {', ...'}
          </Paper>
        </FontTooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Copy IDs">
          <IconButton color="primary" size="small" onClick={handleClick(itemIds.join(', '), snackPackmsg)}>
            <FileCopy fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Table
        title={title || 'Catalog Updates'}
        keyColumn="_id"
        items={data}
        columns={[
          { name: 'Type', data: item => item['type'] },
          {
            name: 'Product IDs',
            data: item => {
              if (!item.productIds) {
                return ''
              }
              if (item['productIds'].length > LIMIT_IDS) {
                return <ColumnIds itemIds={item.productIds} snackPackmsg="product IDs copied" />
              }
              return item['productIds'].join(', ')
            },
          },
          {
            name: 'Variant IDs',
            data: item => {
              if (!item.variantIds) {
                return ''
              }
              if (item['variantIds'].length > LIMIT_IDS) {
                return <ColumnIds itemIds={item.variantIds} snackPackmsg="variant IDs copied" />
              }
              return item['variantIds'].join(', ')
            },
          },
          {
            name: 'Total Products Updated',
            data: item => (item.totalProductsUpdated ? item['totalProductsUpdated'] : '0'),
          },
          {
            name: 'Total Variants Updated',
            data: item => (item.totalVariantsUpdated ? item['totalVariantsUpdated'] : '0'),
          },
          { name: 'Last Updated', data: item => formatDate(item['lastUpdated']) },
        ]}
      />
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
      />
    </>
  )
}
