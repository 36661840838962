import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchPimToEtlBucketInfo = (token, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: '/api/pimToEtlBucket',
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
