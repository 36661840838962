import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchApiGoConfig = (token, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: '/api/kubernetes/apigoConfigMap',
      params: {
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const putApiGoConfig = (token, environment, configMap) =>
  tryP(() =>
    axios({
      method: 'PUT',
      url: '/api/kubernetes/apigoConfigMap',
      data: {
        environment,
        configMap,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
