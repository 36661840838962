import { getCatalogs, setCatalogs } from './catalogs.redux'
import { fetchCatalogs } from './catalogs.io'

const getCatalogsEpic = {
  type: getCatalogs.toString(),
  do: (_, { auth, environments }) =>
    fetchCatalogs(auth.token, environments.selectedEnvironment).map(res => setCatalogs(res.data)),
}

export default [getCatalogsEpic]
