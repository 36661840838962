import React from 'react'
import { Table } from '../reusables/Table'
import { stringifyBoolean } from '../../utils'
import { useHistory } from 'react-router'
import { formatDate } from '../../utils/string'

export const VariantTable = ({ data, title }) => {
  const history = useHistory()

  return (
    <Table
      title={title || 'Results'}
      keyColumn="sku_id"
      items={data}
      handleRowClick={item => history.push(`/variants/${item['sku_id']}`)}
      columns={[
        { name: 'Sku ID', data: item => item['sku_id'] },
        { name: 'Color Code', data: item => item['color_code'] },
        { name: 'Final Sale', data: item => stringifyBoolean(item['final_sale']) },
        { name: 'Has Extened Lengths', data: item => stringifyBoolean(item['has_extended_lengths']) },
        { name: 'Is Online', data: item => stringifyBoolean(item['is_online']) },
        { name: 'Master ID', data: item => item['masterId'] },
        { name: 'New Arrival', data: item => stringifyBoolean(item['new_arrival']) },
        { name: 'Online Excl.', data: item => stringifyBoolean(item['online_exclusive']) },
        { name: 'Season Code', data: item => item['season_code'] },
        { name: 'Size', data: item => item['size'] },
        { name: 'Style Color ID', data: item => item['style_color_id'] },
        { name: 'Tax code', data: item => item['tax_code'] },
        {
          name: 'List Price (CAD/USD)',
          data: item => `${item.listPrice ? item['listPrice'].CAD + '/' + item['listPrice'].USD : 0}`,
        },
        {
          name: 'Sales Price (CAD/USD)',
          data: item => `${item.salePrice ? item['salePrice'].CAD + '/' + item['salePrice'].USD : 0}`,
        },
        { name: 'Last Updated', data: item => formatDate(item['lastUpdated']) },
      ]}
    />
  )
}
