import { createAction, handleActions } from 'redux-actions'

export const loginSuccess = createAction('AUTH_LOGIN')
export const logout = createAction('AUTH_LOGOUT')
export const tryLogin = createAction('AUTH_TRY_LOGIN')
export const setLoading = createAction('AUTH_SET_LOADING')
export const setDomainSnackbar = createAction('AUTH_SET_DOMAIN_SNACKBAR')
export const refreshToken = createAction('AUTH_REFRESH_TOKEN')
export const setToken = createAction('AUTH_SET_TOKEN')

const INITIAL_STATE = {
  authenticated: false,
  userInfo: null,
  token: null,
  showDomainSnackbar: false,
  loading: false,
}

export const authReducer = handleActions(
  {
    [loginSuccess]: (state, { payload: { userInfo, token } }) => ({
      ...state,
      authenticated: true,
      userInfo,
      token,
      loading: false,
    }),
    [logout]: state => ({
      ...state,
      authenticated: false,
      userInfo: null,
      token: null,
    }),
    [setDomainSnackbar]: (state, { payload: showDomainSnackbar }) => ({
      ...state,
      showDomainSnackbar,
    }),
    [setToken]: (state, { payload: token }) => ({
      ...state,
      token,
    }),
    [setLoading]: (state, { payload: loading }) => ({
      ...state,
      loading,
    }),
  },
  INITIAL_STATE
)
