import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Home } from './modules/home/home'
import { Login } from './modules/login/login'
import { Products } from './modules/products/products'
import { Product } from './modules/products/product'
import { Variants } from './modules/variants/variants'
import { Variant } from './modules/variants/variant'
import { ColorGroups } from './modules/colorGroups/colorGroups'
import { RmsBucket } from './modules/rmsBucket/rmsBucket'
import { PimToEtlBucket } from './modules/pimToEtlBucket/pimToEtlBucket'
import { DigitalSftp } from './modules/digitalSftp/digitalSftp'
import { Crons } from './modules/crons/crons'
import { Stores } from './modules/stores/stores'
import { Catalogs } from './modules/catalogs/catalogs'
import { ApiGoConfig } from './modules/apigoConfig/apigoConfig'
import { Search } from './modules/search/search'

export const Routes = () => (
  <Switch>
    <Route component={Home} exact path="/" />
    <Route component={Login} path="/login" />
    <Route component={Products} path="/products" exact />
    <Route component={Product} path="/products/:id" />
    <Route component={Variants} path="/variants" exact />
    <Route component={Variant} path="/variants/:id" />
    <Route component={ColorGroups} path="/colorGroups" exact />
    <Route component={Stores} path="/stores" exact />
    <Route component={RmsBucket} path="/rmsBucket" exact />
    <Route component={PimToEtlBucket} path="/pimToEtlBucket" exact />
    <Route component={DigitalSftp} path="/digitalSftp" exact />
    <Route component={Crons} path="/crons" />
    <Route component={Catalogs} path="/catalogs" />
    <Route component={ApiGoConfig} path="/apigoConfig" />
    <Route component={Search} path="/search" />
  </Switch>
)
