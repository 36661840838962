import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { useSelector, useDispatch } from 'react-redux'
import { tryLogin, setDomainSnackbar, setLoading } from '../auth/auth.redux'
import styled from 'styled-components'
import { Redirect } from 'react-router'
import { Snackbar, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LoadingScreen } from '../../loading/loading'

export const Login = () => {
  const { authenticated, showDomainSnackbar, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setDomainSnackbar(false))
  }

  if (loading) {
    return <LoadingScreen />
  }

  const onSuccess = response => {
    dispatch(setLoading(true))
    dispatch(tryLogin(response))
  }

  return (
    <>
      <CenteredPage>
        {authenticated ? (
          <Redirect to="/" />
        ) : (
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Login to Auditor"
            onSuccess={onSuccess}
            // TODO: Adding failure case
            onFailure={() => dispatch(setLoading(false))}
            cookiePolicy={'single_host_origin'}
          />
        )}
      </CenteredPage>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showDomainSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your Google account is unauthorized from accessing the Audit Tool."
        action={
          <>
            <Button color="secondary" size="small" onClick={handleClose}>
              OK
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  )
}

const CenteredPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
