import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import meatball from 'meatball'
import { reducers } from './reducers'
import { epics } from './epics'

// Chrome Dev Tools Redux Debugger
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
          // support daggy
          replacer: (key, value) =>
            value && value['@@tag'] ? (Object.keys(value)[0] === 'is' ? value.toString() : value) : value,
        },
      })
    : compose

const rootEpics = [].concat(...Object.values(epics))

/**
 * This sequence of code below is for persisting the authenticated state through the local
 * storage. This way we can easily manage authentication in the front-end without the need
 * to build any complex authentication system in the backend.
 */

/**
 * This code will try to fetch the authenticated information from the local storage and apply
 * it to the initial state.
 */
const persistedAuthObject = localStorage.getItem('reduxAuthState')
  ? JSON.parse(localStorage.getItem('reduxAuthState'))
  : undefined

/**
 * The auth data will only persist if the saved auth object indicates that:
 * - The user is authenticated by the time his authentication data is persisted
 * - The expiry time has not been met
 */
const initialState =
  persistedAuthObject &&
  persistedAuthObject.authenticated &&
  new Date(persistedAuthObject.userInfo.expiryDate).getTime() > Date.now()
    ? { auth: persistedAuthObject }
    : {}

const store = createStore(
  combineReducers(reducers),
  initialState,
  composeEnhancers(applyMiddleware(meatball(rootEpics)))
)

/**
 * Sets up an event listener to save auth information to the local storage every time
 * the store changes.
 */
store.subscribe(() => {
  localStorage.setItem('reduxAuthState', JSON.stringify(store.getState().auth))
})

export default store
