import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InventoryTable } from './inventoryTable'
import { fetchInventoryByVariantId } from './variants.io'
import { LoadingScreen } from '../../loading/loading'

export const Inventory = ({ variantId }) => {
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchInventoryByVariantId(token, variantId, selectedEnvironment).fork(
      () => {},
      ({ data }) => {
        setResults(data)
        setLoading(false)
      }
    )
  }, [token, variantId, selectedEnvironment])

  if (loading) {
    return <LoadingScreen />
  }

  return <InventoryTable data={results} title="Inventory History Changes" />
}
