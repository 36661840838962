import { createAction, handleActions } from 'redux-actions'

export const getEnvironments = createAction('ENVIRONMENTS_GET_ENVIRONMENTS')
export const setEnvironments = createAction('ENVIRONMENTS_SET_ENVIRONMENTS')
export const setSelectedEnvironment = createAction('ENVIRONMENTS_SET_SELECTED_ENVIRONMENT')

const INITIAL_STATE = {
  items: [],
  selectedEnvironment: '',
}

export const environmentsReducer = handleActions(
  {
    [setEnvironments]: (state, { payload }) => ({
      ...state,
      items: payload,
      selectedEnvironment: payload[0],
    }),
    [setSelectedEnvironment]: (state, { payload }) => ({
      ...state,
      selectedEnvironment: payload,
    }),
  },
  INITIAL_STATE
)
