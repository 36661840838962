import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchProducts = (token, searchCriteria, environment) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/products',
      data: {
        searchCriteria,
        environment,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchProductById = (token, id, environment) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/api/products/${id}`,
      baseURL,
      params: {
        environment,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
