import React from 'react'
import { useSelector } from 'react-redux'
import { fetchStores } from './stores.io'
import { Table } from '../reusables/Table'
import { CardContent, makeStyles, Toolbar, Typography, Card } from '@material-ui/core'
import { useSearchForm } from '../reusables/useSearchForm'
import { formatDate } from '../../utils/string'

const useStyles = makeStyles(theme => ({
  form: {
    padding: 15,
  },
  card: {
    marginBottom: 15,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const Stores = () => {
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)

  const { form, setLoading, setResults, results } = useSearchForm({
    onSubmit: value => {
      setLoading(true)
      fetchStores(token, value, selectedEnvironment).fork(
        () => {},
        ({ data }) => {
          setResults(data)
          setLoading(false)
        }
      )
    },
    fields: [
      { name: 'storeName', label: 'Store Name', initialValue: '', type: 'input' },
      { name: 'address1', label: 'Address 1', initialValue: '', type: 'input' },
      { name: 'address2', label: 'Address 2', initialValue: '', type: 'input' },
      { name: 'chainName', label: 'Chain Name', initialValue: '', type: 'input' },
      { name: 'city', label: 'City', initialValue: '', type: 'input' },
      { name: 'country', label: 'Country', initialValue: '', type: 'input' },
      { name: 'mallName', label: 'Mall Name', initialValue: '', type: 'input' },
      { name: 'phoneNumber', label: 'Phone Number', initialValue: '', type: 'input' },
      { name: 'postalCode', label: 'Postal Code', initialValue: '', type: 'input' },
      { name: 'shipToStore', label: 'Ship To Store', initialValue: '', type: 'input' },
      { name: 'state', label: 'State', initialValue: '', type: 'input' },
      { name: 'storeType', label: 'Store Type', initialValue: '', type: 'input' },
    ],
  })

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            Stores
          </Typography>
        </Toolbar>
        <CardContent>
          <Typography component="div">You can search for stores using the criteria below:</Typography>
          {form}
        </CardContent>
      </Card>
      <Table
        title="Results"
        keyColumn="id"
        items={results}
        columns={[
          { name: 'Store ID', data: item => item['id'] },
          { name: 'Address 1', data: item => item['address1'] },
          { name: 'Address 2', data: item => item['address2'] },
          { name: 'Chain', data: item => item['chain'] },
          { name: 'Chain Name', data: item => item['chainName'] },
          { name: 'City', data: item => item['city'] },
          { name: 'Country', data: item => item['country'] },
          { name: 'Fax Number', data: item => item['faxNumber'] },
          { name: 'ISP Eligible', data: item => item['ispEligible'] },
          { name: 'Email Address', data: item => item['ispEmailAddress'] },
          { name: 'Latitude', data: item => item['latitude'] },
          { name: 'Longitude', data: item => item['longitude'] },
          { name: 'Location Category', data: item => item['locationCategory'] },
          { name: 'Mall Name', data: item => item['mallName'] },
          { name: 'Max Transit Time', data: item => item['maxTransitTime'] },
          { name: 'Min Transit Time', data: item => item['minTransitTime'] },
          { name: 'Message', data: item => item['message'] },
          { name: 'Phone Number', data: item => item['phoneNumber'] },
          { name: 'Postal Code', data: item => item['postalCode'] },
          { name: 'Ship To Store', data: item => item['shipToStore'] },
          { name: 'State', data: item => item['state'] },
          {
            name: 'Opening Hours',
            data: item => `${(item['openingHours'] && item['openingHours'].split(',').join(' ')) || ''}`,
          },
          {
            name: 'Closing Hours',
            data: item => `${(item['closingHours'] && item['closingHours'].split(',').join(' ')) || ''}`,
          },
          { name: 'Store Name', data: item => item['storeName'] },
          { name: 'Store Type', data: item => item['storeType'] },
          { name: 'Return Carrier Account', data: item => item['returnCarrierAccount'] },
          { name: 'Return Label Name', data: item => item['returnLabelName'] },
          { name: 'Return Location', data: item => item['returnLocation'] },
          { name: 'Last Update', data: item => formatDate(item['lastUpdated']) },
        ]}
      />
    </>
  )
}
