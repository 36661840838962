import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Table } from '../reusables/Table'
import { useSelector } from 'react-redux'
import { LoadingScreen } from '../../loading/loading'
import {
  Typography,
  TableContainer,
  Toolbar,
  Paper,
  Table as MUITable,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core'
import { stringifyBoolean } from '../../utils'
import { fetchProductById } from './products.io'
import { fetchVariantsByIds } from '../variants/variants.io'
import { VariantTable } from '../variants/variantTable'
import { formatDate } from '../../utils/string'

const useStyles = makeStyles({
  details: {
    marginBottom: 15,
  },
})

export const Product = () => {
  const classes = useStyles()
  const match = useRouteMatch()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [variants, setVariants] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchProductById(token, match.params.id, selectedEnvironment)
      .chain(({ data }) => {
        setProduct(data)
        return fetchVariantsByIds(token, data.variants, selectedEnvironment)
      })
      .fork(
        () => {},
        ({ data }) => {
          setVariants(data)
          setLoading(false)
        }
      )
  }, [match.params.id, selectedEnvironment, token])

  if (loading) {
    return <LoadingScreen />
  }

  if (!product) {
    return (
      <div>
        <Typography variant="h6">The styleId {match.params.id} does not exist.</Typography>
      </div>
    )
  }

  const summaryRows = [
    { title: 'Style ID', data: product['style_id'] },
    { title: 'Class', data: product['class'] },
    { title: 'Department', data: product['department'] },
    { title: 'Discountable', data: stringifyBoolean(product['discountable']) },
    { title: 'Division', data: product['division'] },
    { title: 'Size Bucket', data: product['size_bucket'] },
    { title: 'Non-returnable', data: stringifyBoolean(product['non_returnable']) },
    { title: 'Sellable Country', data: product['sellable_country'] },
    { title: 'Subclass', data: product['subclass'] },
    { title: 'Taxable', data: stringifyBoolean(product['taxable']) },
    { title: 'Last Update', data: formatDate(product['lastUpdated']) },
    { title: 'Last PIM Update', data: formatDate(product['lastPimUpdate']) },
  ]

  return (
    <>
      <TableContainer className={classes.details} component={Paper}>
        <Toolbar>
          <Typography component="div" variant="h6">
            <strong>PRODUCT DETAILS</strong>
          </Typography>
        </Toolbar>

        <MUITable>
          <TableBody>
            {summaryRows.map(({ title, data }) => (
              <TableRow key={title}>
                <TableCell>
                  <strong>{title}</strong>
                </TableCell>
                <TableCell>{data}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>

      <Table
        title="Style Colors"
        keyColumn="style_color_id"
        items={product.styleColors}
        columns={[
          { name: 'ID', data: item => item['style_color_id'] },
          {
            name: 'Name (EN/FR)',
            data: item => `${item.translations.en['product_name']} / ${item.translations.fr['product_name']}`,
          },
          { name: 'Color Code', data: item => item['color_code'] },
          {
            name: 'Color Web Name (EN/FR)',
            data: item => `${item.translations.en['color_web_name']} / ${item.translations.fr['color_web_name']}`,
          },
          { name: 'Final Sale', data: item => stringifyBoolean(item['final_sale']) },
          { name: 'Non-Online Exclusive', data: item => stringifyBoolean(item['online_exclusive']) },
          { name: 'Season Code', data: item => item['season_code'] },
          { name: 'New Arrival', data: item => stringifyBoolean(item['new_arrival']) },
          { name: 'Has Extended Lengths', data: item => stringifyBoolean(item['has_extended_lengths']) },
          { name: 'Is Online', data: item => stringifyBoolean(item['is_online']) },
          { name: 'Searchable Flag', data: item => stringifyBoolean(item['searchable_flag']) },
        ]}
      />

      <VariantTable data={variants} title="Variants" />
    </>
  )
}
