import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Chip,
  Button,
  Dialog,
  DialogContentText,
  DialogActions,
  Typography,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@material-ui/core'
import { Table } from '../reusables/Table'
import { fetchCrons } from './crons.io'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'
import styled from 'styled-components'
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons'
import { ellipsize, formatDate } from '../../utils/string'
import { LoadingScreen } from '../../loading/loading'

const useStyles = makeStyles({
  header: {
    background: '#ED2939',
    color: 'white',
  },
  okChip: {
    backgroundColor: '#34eb6e',
    color: 'white',
    fontWeight: 'bold',
  },
  errorChip: {
    backgroundColor: '#eb3a34',
    color: 'white',
    fontWeight: 'bold',
  },
  okIcon: {
    backgroundColor: '#34eb6e',
    marginLeft: 5,
    borderRadius: 5,
    cursor: 'pointer',
    color: 'white',
  },
  errorIcon: {
    backgroundColor: '#eb3a34',
    marginLeft: 5,
    borderRadius: 5,
    color: 'white',
    cursor: 'pointer',
  },
})

export const Crons = () => {
  const classes = useStyles()
  const { selectedEnvironment } = useSelector(state => state.environments)
  const { token } = useSelector(state => state.auth)
  const [crons, setCrons] = useState([])
  const [loading, setLoading] = useState(false)
  const cronTypes = crons.reduce(
    (acc, cur) =>
      !acc.find(cronType => cronType.name === cur['type'])
        ? [...acc, { name: cur['type'], progress: [cur] }]
        : acc.map(cronType =>
            cronType.name === cur['type'] && cronType.progress.length < 20
              ? { ...cronType, progress: [...cronType.progress, cur] }
              : cronType
          ),
    []
  )

  useEffect(() => {
    setLoading(true)
    fetchCrons(token, selectedEnvironment).fork(
      () => {},
      ({ data }) => {
        setLoading(false)
        setCrons(data)
      }
    )
  }, [selectedEnvironment, token])

  const chips = {
    error: <Chip size="small" label="Error" className={classes.errorChip} />,
    info: <Chip size="small" label="OK" className={classes.okChip} />,
  }

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <div>
      <Table
        title="Cron Jobs"
        keyColumn="_id"
        items={crons}
        initialRowsPerPage={10}
        columns={[
          { name: 'Status', width: 100, data: item => chips[item['level']] },
          { name: 'Type', width: 150, data: item => item['type'] },
          { name: 'Start At', data: item => formatDate(item['startedAt']) },
          { name: 'Complete At', data: item => formatDate(item['completedAt']) },
          {
            name: 'Error Summary',
            width: 350,
            data: item => item['error'] && <SmallCode>{ellipsize(item['error'], 40)}</SmallCode>,
          },
          {
            name: 'Details',
            data: item =>
              (item['error'] || Object.values(item.info).some(info => info.length > 0)) && <CronDetail item={item} />,
          },
        ]}
      />
      <Table
        title="Overview"
        keyColumn="name"
        items={cronTypes}
        noPagination
        columns={[
          { name: 'Type', width: 150, data: item => item['name'] },
          { name: 'Progress (Newest to Oldest)', width: 150, data: item => <ProgressList item={item} /> },
        ]}
      />
    </div>
  )
}

const ProgressList = ({ item }) => {
  const classes = useStyles()
  return (
    <div>
      {item.progress.map(({ level, error, startedAt, completedAt, _id }) => (
        <span key={_id}>
          {level === 'info' ? (
            <Tooltip title={`${formatDate(startedAt)} - ${formatDate(completedAt)}`}>
              <CheckIcon className={classes.okIcon} />
            </Tooltip>
          ) : (
            <Tooltip
              title={`${formatDate(startedAt)} - ${formatDate(completedAt)}, Error: ${
                error || 'No error message provided.'
              }`}
            >
              <CloseIcon className={classes.errorIcon} />
            </Tooltip>
          )}
        </span>
      ))}
    </div>
  )
}

const CronDetail = ({ item }) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined">
        Details
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {item['type']} at {format(new Date(item['startedAt']), 'dd/MM/yyyy HH:mm:ss')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {item['error'] && (
              <>
                <Typography gutterBottom color="textPrimary">
                  Error message:
                </Typography>
                <Code>{item['error']}</Code>
              </>
            )}
            {item.info.sourceFiles.length > 0 && (
              <>
                <Typography gutterBottom color="textPrimary">
                  <strong>Source files:</strong>
                </Typography>
                {item.info.sourceFiles.map(({ path, bucket }) => (
                  <Typography key={path} gutterBottom color="textPrimary">
                    - Path: <em>{path}</em>, bucket: <em>{bucket}</em>
                  </Typography>
                ))}
              </>
            )}
            {item.info.mongoOperations.length > 0 && (
              <>
                <Typography gutterBottom color="textPrimary">
                  <strong>Mongo operations:</strong>
                </Typography>
                {item.info.mongoOperations.map(({ collection, operation, count }) => (
                  <Typography key={`${collection}${operation}`} gutterBottom color="textPrimary">
                    - Collection: <em>{collection}</em>, operation: <em>{operation}</em>, count: <em>{count}</em>
                  </Typography>
                ))}
              </>
            )}
            {item.info.destinationFiles.length > 0 && (
              <>
                <Typography gutterBottom color="textPrimary">
                  <strong>Destination files:</strong>
                </Typography>
                {item.info.destinationFiles.map(({ host, path }) => (
                  <Typography key={`${host}${path}`} gutterBottom color="textPrimary">
                    - Host: <em>{host}</em>, path: <em>{path}</em>
                  </Typography>
                ))}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const Code = styled.div`
  font-size: 1rem;
  background: #7f8781;
  padding: 8px;
  color: white;
  border-radius: 5px;
  font-family: monospace;
`

const SmallCode = styled(Code)`
  font-size: 0.8rem;
`
