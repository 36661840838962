import React from 'react'
import { useSelector } from 'react-redux'
import { fetchProducts } from './products.io'
import { useHistory } from 'react-router'
import { Table } from '../reusables/Table'
import { stringifyBoolean } from '../../utils'
import { CardContent, makeStyles, Toolbar, Typography, Card } from '@material-ui/core'
import { useSearchForm } from '../reusables/useSearchForm'
import { formatDate } from '../../utils/string'

const useStyles = makeStyles(theme => ({
  form: {
    padding: 15,
  },
  card: {
    marginBottom: 15,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const Products = () => {
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const history = useHistory()
  const { selectedEnvironment } = useSelector(state => state.environments)

  const { form, setLoading, setResults, results } = useSearchForm({
    onSubmit: value => {
      setLoading(true)
      fetchProducts(token, value, selectedEnvironment).fork(
        () => {},
        ({ data }) => {
          setResults(data)
          setLoading(false)
        }
      )
    },
    fields: [
      { name: 'style_id', label: 'Style ID', initialValue: '', type: 'input' },
      { name: 'class', label: 'Class', initialValue: '', type: 'number' },
      { name: 'department', label: 'Department', initialValue: '', type: 'number' },
      { name: 'discountable', label: 'Discountable', initialValue: '', type: 'boolean' },
      { name: 'division', label: 'Division', initialValue: '', type: 'number' },
      { name: 'non_returnable', label: 'Non-Returnable', initialValue: '', type: 'boolean' },
      { name: 'sellable_country', label: 'Sellable Country', initialValue: '', type: 'input' },
      { name: 'size_bucket', label: 'Size Bucket', initialValue: '', type: 'input' },
      { name: 'subclass', label: 'Subclass', initialValue: '', type: 'number' },
      { name: 'taxable', label: 'Taxable', initialValue: '', type: 'boolean' },
    ],
  })

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            Products
          </Typography>
        </Toolbar>
        <CardContent>
          <Typography component="div">You can search for products using the criteria below:</Typography>
          {form}
        </CardContent>
      </Card>
      <Table
        title="Results"
        keyColumn="style_id"
        handleRowClick={item => history.push(`/products/${item['style_id']}`)}
        items={results}
        columns={[
          { name: 'Style ID', data: item => item['style_id'] },
          { name: 'Class', data: item => item['class'] },
          { name: 'Department', data: item => item['department'] },
          { name: 'Discountable', data: item => stringifyBoolean(item['discountable']) },
          { name: 'Division', data: item => item['division'] },
          { name: 'Non-Returnable', data: item => stringifyBoolean(item['non_returnable']) },
          { name: 'Sellable Country', data: item => item['sellable_country'] },
          { name: 'Size Bucket', data: item => item['size_bucket'] },
          { name: 'Subclass', data: item => item['subclass'] },
          { name: 'Taxable', data: item => stringifyBoolean(item['taxable']) },
          { name: 'Last Update', data: item => formatDate(item['lastUpdated']) },
          { name: 'Last PIM Update', data: item => formatDate(item['lastPimUpdate']) },
        ]}
      />
    </>
  )
}
