import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux'
import { LoadingScreen } from '../../loading/loading'
import {
  Typography,
  TableContainer,
  Toolbar,
  Paper,
  Table as MUITable,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from '@material-ui/core'
import { stringifyBoolean } from '../../utils'
import { fetchVariantById, fetchSFCCVariantById, fetchOmniVariantById } from './variants.io'
import { parallel } from 'fluture'
import styled, { css } from 'styled-components'
import {
  Check as CheckIcon,
  CancelPresentation as CancelPresentationIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import format from 'date-fns/format'
import { formatDate } from '../../utils/string'
import { Inventory } from './inventory'

const useStyles = makeStyles({
  details: {
    marginTop: 15,
    marginBottom: 15,
  },
  transitorCard: {
    width: 350,
  },
  transitorCardUnavailable: {
    width: 350,
    background: '#eb3a34',
    color: 'white',
  },
  cardHeader: {
    fontWeight: 'bold',
  },
})

const statuses = {
  success: {
    Icon: CheckIcon,
    color: '#2bad4e',
    tip: {
      etl: 'OMNI and ETL inventories are compatible with each other',
      sfcc: 'OMNI and SFCC inventories are compatible with each other',
    },
  },
  error: {
    Icon: CloseIcon,
    color: '#eb3a34',
    tip: {
      etl: 'Data is not available',
      sfcc: 'Data is not available',
    },
  },
  warning: {
    Icon: WarningIcon,
    color: '#ebc634',
    tip: {
      etl:
        'Incompatibility between ETL and OMNI will be reconciled overnight, please check again in the following morning',
      sfcc: 'May be an issue with real time inventory updates between OMNI and SFCC',
    },
  },
}

export const Variant = () => {
  const classes = useStyles()
  const match = useRouteMatch()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)
  const [loading, setLoading] = useState(false)
  const [etlVariant, setEtlVariant] = useState(null)
  const [sfccVariant, setSfccVariant] = useState(null)
  const [omniVariant, setOmniVariant] = useState(null)

  useEffect(() => {
    setLoading(true)
    parallel(Infinity, [
      fetchVariantById(token, match.params.id, selectedEnvironment),
      fetchSFCCVariantById(token, match.params.id, selectedEnvironment),
      fetchOmniVariantById(token, match.params.id, selectedEnvironment),
    ]).fork(
      () => {},
      ([etlVariant, sfccVariant, omniVariant]) => {
        setEtlVariant(etlVariant.data)
        setSfccVariant(sfccVariant.data.data)
        setOmniVariant(omniVariant.data.data)
        setLoading(false)
      }
    )
  }, [match.params.id, selectedEnvironment, token])

  if (loading) {
    return <LoadingScreen />
  }

  if (!etlVariant) {
    return (
      <div>
        <Typography variant="h6">The variant {match.params.id} does not exist.</Typography>
      </div>
    )
  }

  const summaryRows = [
    { title: 'SKU ID', data: etlVariant['sku_id'] },
    { title: 'Color Code', data: etlVariant['color_code'] },
    { title: 'Final Sale', data: stringifyBoolean(etlVariant['final_sale']) },
    { title: 'Has Extd. Lengths', data: stringifyBoolean(etlVariant['has_extended_lengths']) },
    { title: 'Is Online', data: stringifyBoolean(etlVariant['is_online']) },
    { title: 'Master ID', data: etlVariant['masterId'] },
    { title: 'New Arrival', data: stringifyBoolean(etlVariant['new_arrival']) },
    { title: 'Online Exclusive', data: stringifyBoolean(etlVariant['online_exclusive']) },
    { title: 'Season Code', data: etlVariant['season_code'] },
    { title: 'Size', data: etlVariant['size'] },
    { title: 'Style Color ID', data: etlVariant['style_color_id'] },
    { title: 'Tax Code', data: etlVariant['tax_code'] },
    {
      title: 'List Price (CAD/USD)',
      data: etlVariant.listPrice ? etlVariant['listPrice'].CAD + '/' + etlVariant['listPrice'].USD : 0,
    },
    {
      title: 'Sales Price (CAD/USD)',
      data: etlVariant.salePrice ? etlVariant['salePrice'].CAD + '/' + etlVariant['salePrice'].USD : 0,
    },
    { title: 'Last Updated', data: formatDate(etlVariant['lastUpdated']) },
  ]

  return (
    <>
      <TransitorParent>
        <TransitorCard
          title="OMNI"
          isUnavailable={!omniVariant}
          caStock={omniVariant.ca?.Quantity}
          usStock={omniVariant.us?.Quantity}
        />
      </TransitorParent>

      <TransitorChild>
        <TransitorCard
          title="ETL"
          isUnavailable={!etlVariant}
          caStock={etlVariant?.inventory?.ca}
          caLastUpdate={etlVariant.lastUpdated}
          usStock={etlVariant?.inventory?.us}
          usLastUpdate={etlVariant.lastUpdated}
        />

        <LeftLineContainer>
          <TransitorCheck isError={!etlVariant} type={'etl'} />
        </LeftLineContainer>

        <RightLineContainer>
          <TransitorCheck
            isError={!sfccVariant}
            isWarning={
              sfccVariant?.us['stock_level'] !== etlVariant?.inventory?.us ||
              sfccVariant?.ca['stock_level'] !== etlVariant?.inventory?.ca
            }
            type={'sfcc'}
          />
        </RightLineContainer>

        <TransitorCard
          title="SFCC"
          isUnavailable={!sfccVariant}
          caStock={sfccVariant?.ca['stock_level']}
          caLastUpdate={sfccVariant?.ca['last_modified']}
          caWarning={sfccVariant?.ca['stock_level'] !== etlVariant?.inventory?.ca}
          usStock={sfccVariant?.us['stock_level']}
          usLastUpdate={sfccVariant?.us['last_modified']}
          usWarning={sfccVariant?.us['stock_level'] !== etlVariant?.inventory?.us}
        />
      </TransitorChild>

      <TableContainer className={classes.details} component={Paper}>
        <Toolbar>
          <Typography component="div" variant="h6">
            <strong>ETL</strong>
          </Typography>
        </Toolbar>

        <MUITable>
          <TableBody>
            {summaryRows.map(({ title, data }) => (
              <TableRow key={title}>
                <TableCell>
                  <strong>{title}</strong>
                </TableCell>
                <TableCell>{data}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
      <Inventory variantId={match.params.id} />
    </>
  )
}

const TransitorCheck = ({ isError, isWarning, type }) => {
  const { Icon, color, tip } = statuses[isError ? 'error' : isWarning ? 'warning' : 'success']

  return (
    <CheckWrapper color={color}>
      <Tooltip title={tip[type]}>
        <Icon fontSize="large" />
      </Tooltip>
    </CheckWrapper>
  )
}

const TransitorCard = ({
  caStock,
  usStock,
  caLastUpdate,
  usLastUpdate,
  title,
  isUnavailable,
  caWarning,
  usWarning,
}) => {
  const classes = useStyles()

  if (isUnavailable) {
    return (
      <Card className={classes.transitorCardUnavailable}>
        <CardHeader classes={{ title: classes.cardHeader }} title={title} />
        <CardContent>
          <CenteredContent>
            <CancelPresentationIcon fontSize="large" />
            <Typography variant="h6">Data Unavailable</Typography>
          </CenteredContent>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader className={classes.transitorCard} classes={{ title: classes.cardHeader }} title={title} />
      <CardContent>
        <TransitorSubcard
          gutterBottom
          color={statuses[caWarning ? 'warning' : Number.isInteger(caStock) ? 'success' : 'error'].color}
        >
          <Typography>
            <strong>
              <span role="img" aria-label="Canadian Flag">
                🇨🇦
              </span>{' '}
              CA Stock:
            </strong>{' '}
            {Number.isInteger(caStock) ? caStock : 'N/A'}
          </Typography>
          <Typography>
            (Last updated: {caLastUpdate ? format(new Date(caLastUpdate), 'dd/MM/yyyy HH:mm:ss') : 'N/A'})
          </Typography>
        </TransitorSubcard>

        <TransitorSubcard
          color={statuses[usWarning ? 'warning' : Number.isInteger(usStock) ? 'success' : 'error'].color}
        >
          <Typography>
            <strong>
              <span role="img" aria-label="US Flag">
                🇺🇸
              </span>{' '}
              US Stock:
            </strong>{' '}
            {Number.isInteger(usStock) ? usStock : 'N/A'}
          </Typography>
          <Typography>
            (Last updated: {usLastUpdate ? format(new Date(usLastUpdate), 'dd/MM/yyyy HH:mm:ss') : 'N/A'})
          </Typography>
        </TransitorSubcard>
      </CardContent>
    </Card>
  )
}

const TransitorSubcard = styled.div`
  background: ${props => props.color};
  color: white;
  padding: 8px;
  ${props =>
    props.gutterBottom &&
    css`
      margin-bottom: 8px;
    `}
  border-radius: 5px;
`

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const TransitorParent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

const TransitorChild = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.color};
`

const LeftLineContainer = styled.div`
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 7rem;
  width: 16rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const RightLineContainer = styled.div`
  border-bottom: 1px solid #ccc;
  height: 7rem;
  width: 16rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
