import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Snackbar,
  IconButton,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formField: {
    margin: theme.spacing(1),
    width: 170,
  },
  submitButton: {
    marginTop: 20,
    height: 36,
    width: 100,
  },
  spinner: {
    color: 'white',
  },
}))

/**
 * This hook allows you to create an automatically generated search form without manually writing every
 * single form field.
 *
 * @param {object} props
 * @param {(formValues: object) => void} props.onSubmit this callback function allows you to add a form
 * submission with the formValues as the argument.
 * @param {Array<{
 *  name: string,
 *  label: string,
 *  initialValue: string | number,
 *  options: array,
 *  type: 'input' | 'number' | 'boolean' | 'dropdown'
 * }>} props.fields this Array props allows you to generate an automated search form without actually
 * writing the JSX for each form field. The 'name' property will be the object name of a form field,
 * and the property name of the form value corresponding to that form field. 'label' is the display name
 * of the form field. 'initialValue' is self-descriptive.
 * The 'options' property is an array of all the items you wish to include in the custom dropdown list.
 * The 'type' property allow you to create a form field as either a string input, a number input, a boolean selector or a dropdown selector.
 * You can add more form types to this if you wish.
 * @returns {{
 *  results: object | any[],
 *  setResults: (data: object | any[]) => void,
 *  setLoading: (boolean) => void,
 *  form: JSX.Element
 * }} the returned object are divided into four
 * important values:
 * - results: after the form is submitted, you will expect the onSubmit to pass the result of the
 * form submission via setResult. You can then use the results object or array and use it in the code
 * outside of this hook
 * - setResults: this function sets the result of the onSubmit function to the results state
 * - setLoading: sets the internal loading UI of the form submission to true or false
 * - form: this is the UI component for the automated form. You can apply this JSX to your React UI by
 * doing: (
 *  <div>{form}</div>
 * )
 */
export const useSearchForm = ({ onSubmit, fields }) => {
  const classes = useStyles()
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [showEmptyResultSnackbar, setEmptyResultSnackbar] = useState(false)

  const { handleSubmit, handleChange, values, dirty } = useFormik({
    onSubmit,
    initialValues: fields.reduce((acc, { name, initialValue }) => ({ ...acc, [name]: initialValue }), {}),
  })

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setEmptyResultSnackbar(false)
  }

  return {
    results,
    setLoading,
    setResults: result => {
      if (result.length === 0) {
        setEmptyResultSnackbar(true)
      }
      setResults(result)
    },
    form: (
      <form onSubmit={handleSubmit}>
        <div>
          {fields.map(({ type, name, label, options }) => {
            switch (type) {
              case 'input':
              case 'number':
                return (
                  <TextField
                    key={name}
                    className={classes.formField}
                    id={name}
                    name={name}
                    label={label}
                    onChange={handleChange}
                    value={values[name]}
                    type={type}
                  />
                )
              case 'boolean':
                return (
                  <FormControl key={name} className={classes.formField}>
                    <InputLabel id={name}>{label}</InputLabel>
                    <Select labelId={label} id={name} name={name} value={values[name]} onChange={handleChange}>
                      <MenuItem value="">Any</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                )
              case 'dropdown':
                return (
                  <FormControl key={name} className={classes.formField}>
                    <InputLabel id={name}>{label}</InputLabel>
                    <Select labelId={label} id={name} name={name} value={values[name]} onChange={handleChange}>
                      {options.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              default:
                return null
            }
          })}
        </div>
        <Button type="submit" className={classes.submitButton} variant="contained" color="primary" disabled={!dirty}>
          {loading ? <CircularProgress className={classes.spinner} size={20} /> : 'Search'}
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showEmptyResultSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Your search returns no result."
          action={
            <>
              <Button color="secondary" size="small" onClick={handleClose}>
                OK
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </form>
    ),
  }
}
