import React from 'react'
import { useSelector } from 'react-redux'
import { VariantTable } from './variantTable'
import { useSearchForm } from '../reusables/useSearchForm'
import { CardContent, makeStyles, Toolbar, Typography, Card } from '@material-ui/core'
import { fetchVariants } from './variants.io'

const useStyles = makeStyles(theme => ({
  form: {
    padding: 15,
  },
  card: {
    marginBottom: 15,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const Variants = () => {
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)

  const { form, setLoading, setResults, results } = useSearchForm({
    onSubmit: value => {
      setLoading(true)
      fetchVariants(token, value, selectedEnvironment).fork(
        () => {},
        ({ data }) => {
          setResults(data)
          setLoading(false)
        }
      )
    },
    fields: [
      { name: 'sku_id', label: 'Sku ID', initialValue: '', type: 'input' },
      { name: 'color_code', label: 'Color Code', initialValue: '', type: 'input' },
      { name: 'final_sale', label: 'Final Sale', initialValue: '', type: 'boolean' },
      { name: 'has_extended_lengths', label: 'Has Extd. Lengths', initialValue: '', type: 'boolean' },
      { name: 'is_online', label: 'Is Online', initialValue: '', type: 'boolean' },
      { name: 'masterId', label: 'Master ID', initialValue: '', type: 'input' },
      { name: 'new_arrival', label: 'New Arrival', initialValue: '', type: 'boolean' },
      { name: 'online_exclusive', label: 'Online Exclusive', initialValue: '', type: 'boolean' },
      { name: 'season_code', label: 'Season Code', initialValue: '', type: 'number' },
      { name: 'size', label: 'Size', initialValue: '', type: 'input' },
      { name: 'style_color_id', label: 'Style Color ID', initialValue: '', type: 'number' },
      { name: 'tax_code', label: 'Tax Code', initialValue: '', type: 'input' },
    ],
  })

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            Variants
          </Typography>
        </Toolbar>
        <CardContent>
          <Typography component="div">You can search for a variant using the criteria below:</Typography>
          {form}
        </CardContent>
      </Card>
      <VariantTable data={results} />
    </>
  )
}
