import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchRmsBucketInfo } from './rmsBucket.io'
import { Table } from '../reusables/Table'
import { makeStyles, Typography } from '@material-ui/core'
import { formatDate } from '../../utils/string'
import { LoadingScreen } from '../../loading/loading'
import { filterBucketFilesByFolder, filterUnprocessedBucketFiles } from '../../utils/filter'

const useStyles = makeStyles({
  typography: {
    paddingBottom: 15,
  },
})

export const RmsBucket = () => {
  const classes = useStyles()
  const { token } = useSelector(state => state.auth)
  const { selectedEnvironment } = useSelector(state => state.environments)
  const [loading, setLoading] = useState(false)
  const [rmsBucketInfo, setRmsBucketInfo] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchRmsBucketInfo(token, selectedEnvironment).fork(
      () => {},
      ({ data }) => {
        setLoading(false)
        setRmsBucketInfo(data)
      }
    )
  }, [selectedEnvironment, token])

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <>
      <Typography variant="h6" component="div" align="center" className={classes.typography}>
        RMS Bucket
      </Typography>
      <Table
        title="Archived"
        keyColumn="name"
        items={filterBucketFilesByFolder(rmsBucketInfo, 'archived')}
        columns={[
          { name: 'File Name', data: item => item['name'] },
          {
            name: 'Last Update',
            data: item => formatDate(item['updated']),
          },
        ]}
      />
      <Table
        title="Failed"
        keyColumn="name"
        items={filterBucketFilesByFolder(rmsBucketInfo, 'failed')}
        columns={[
          { name: 'File Name', data: item => item['name'] },
          {
            name: 'Last Update',
            data: item => formatDate(item['updated']),
          },
        ]}
      />
      <Table
        title="Unprocessed"
        keyColumn="name"
        items={filterUnprocessedBucketFiles(rmsBucketInfo)}
        columns={[
          { name: 'File Name', data: item => item['name'] },
          {
            name: 'Last Update',
            data: item => formatDate(item['updated']),
          },
        ]}
      />
    </>
  )
}
