import { of } from 'fluture'
import { getApiGoConfig, setApiGoConfig, save, onSave, setLoading } from './apigoConfig.redux'
import { fetchApiGoConfig, putApiGoConfig } from './apigoConfig.io'

const getApiGoConfigEpic = {
  type: getApiGoConfig.toString(),
  do: (_, { auth, environments }) =>
    fetchApiGoConfig(auth.token, environments.selectedEnvironment).map(res => setApiGoConfig(res.data)),
}

const saveEpic = {
  type: save.toString(),
  do: (_, { auth, environments, apigoConfig }) =>
    putApiGoConfig(auth.token, environments.selectedEnvironment, apigoConfig.configMap).map(res =>
      setApiGoConfig(res.data)
    ),
}

const onSaveEpic = {
  type: onSave.toString(),
  do: () => of([setLoading(true), save()]),
}

export default [getApiGoConfigEpic, saveEpic, onSaveEpic]
