import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core'
import { setSelectedEnvironment } from './environments.redux'
import { toTitleCase } from '../../utils/string'

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
})

const Environments = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { items, selectedEnvironment } = useSelector(state => state.environments)

  return (
    <FormControl className={classes.input}>
      <InputLabel id="environment-select-label">Environment Selector</InputLabel>
      <Select
        labelId="environment-select-label"
        value={selectedEnvironment}
        onChange={event => dispatch(setSelectedEnvironment(event.target.value))}
      >
        {items &&
          items.map(env => (
            <MenuItem key={env} value={env}>
              {toTitleCase(env)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default Environments
