import React, { useEffect } from 'react'
import { Layout } from './layout'
import { Routes } from './router'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken } from './modules/auth/auth.redux'
import { getEnvironments } from './modules/environments/environments.redux'
import { LoadingScreen } from './loading/loading'
import { useHistory } from 'react-router'

const App = () => {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const history = useHistory()
  const { items, selectedEnvironment } = useSelector(state => state.environments)

  useEffect(() => {
    if (token && items.length === 0) {
      dispatch(getEnvironments())
    }
  }, [dispatch, token, items])

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch])

  useEffect(() => {
    if (window.location.pathname !== '/login' && !token) {
      history.push('/login')
    }
  }, [history, token])

  return <Layout>{token && !selectedEnvironment ? <LoadingScreen /> : <Routes />}</Layout>
}

export default App
