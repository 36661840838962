import React from 'react'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

export const LoadingScreen = () => (
  <Page>
    <CircularProgress />
  </Page>
)

const Page = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
