import format from 'date-fns/format'

/**
 * Capitalize every word of a string. For example: 'john wayne' => 'John Wayne'
 *
 * @param {string} string first letter of words not capitalized
 * @returns {string} new string with every word capitalized
 */
export const toTitleCase = str =>
  str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

/**
 * Shorten a string and add ellipsis if needed. For example:
 * 'All work and no play makes Jack a dull boy' => 'All work and n...'
 *
 * @param {string} string
 * @param {number} number letter limit before being condensed
 * @returns {string} string condensed with an ellipsis
 */
export const ellipsize = (str, limit = 20) => str.slice(0, limit) + (str.length > limit ? '...' : '')

/**
 * @param {string} date Date
 * @returns {string} newly formatted date
 */
export const formatDate = date => (date ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss') : '')
