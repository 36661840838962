import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCatalogs } from './catalogs.redux'
import { CatalogsTable } from './catalogsTable'

export const Catalogs = () => {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const { items } = useSelector(state => state.catalogs)

  useEffect(() => {
    if (token && items.length === 0) {
      dispatch(getCatalogs())
    }
  }, [dispatch, token, items])
  

  return <CatalogsTable data={items} />
}
