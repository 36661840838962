import React from 'react'
import { Table } from '../reusables/Table'
import { stringifyBoolean } from '../../utils'
import { formatDate } from '../../utils/string'

export const InventoryTable = ({ data, title }) => (
  <Table
    title={title || 'Results'}
    keyColumn="_id"
    items={data}
    columns={[
      { name: 'DateTime', data: item => formatDate(item['createdAt']) },
      { name: 'Sku ID', data: item => item['sku_id'] },
      { name: 'Master ID', data: item => item['masterId'] },
      { name: 'Type', data: item => item['type'] },
      { name: 'Message', data: item => item['message'] },
      { name: 'Success', data: item => stringifyBoolean(item['success']) },
    ]}
  />
)
